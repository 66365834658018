import React, { useEffect, useState } from "react";
import "./TopNavBar.css";

import NavbarLogo from "../../src/assets/png/navbarLogo.png";
import { ReactComponent as SearchIcon } from "../../src/assets/svg/SearchIcon.svg";
import NFIHIcon from "../assets/png/NFIH-logo.png";
import NotificationWidget from "./navbarwidgets/NotificationWidget";
import ProfileImg from "../../src/assets/png/ProfileImg.png";
import arrowIcon from "../../src/assets/png/arrowIcon.png";
import LogOutWidget from "./navbarwidgets/LogOutWidget";
import HamburgerMenu from "./HamburgerMenu";
import axios from "axios";
import { useQuery } from "react-query";
import { fetchSingleData, fetchUserDetails } from "../utils/fetchData";
import { getUserSpecificSettings } from "../utils/uiSettingsOperations";
import { jwtDecode } from "jwt-decode";
import { useSettings } from "../context/settingsContext";
import { getFileUrl } from "../utils/fetchSettings";
const apiURL = process.env.REACT_APP_API_URL;

function TopNavBar() {
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState(null);
  const { currentSettings, setCurrentSettings } = useSettings();
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  console.log("currentSettings inside topnavbar", currentSettings);
  // Fetch user data with useQuery
  const storedToken = localStorage.getItem("token");
  const decodedToken = jwtDecode(storedToken);

  const {} = useQuery(
    ["User", "/v1/users"],
    () => fetchUserDetails("/v1/users"),
    {
      onSuccess: (data) => {
        setUserData(data);
      },
    }
  );




  // Close profile dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".profileSection")) {
        setIsProfileClicked(false);
      }
    };

    if (isProfileClicked) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isProfileClicked]);

  const handleProfile = () => {
    setIsProfileClicked(!isProfileClicked);
  };

  // Use a computed value for the image source with fallback
  const logoImageSrc = currentSettings?.image
    ? `${getFileUrl}${currentSettings.image}`
    : NavbarLogo;

  console.log("TopNavBar rendering with settings:", currentSettings);
  console.log("Logo image source:", logoImageSrc);

  return (
    <>
      <div className="topNavbarWrapper">
        <div className="logosection" id="companyLogoId">
          <img
            key={forceUpdate} // Add key to force re-render when image changes
            src={logoImageSrc}
            alt="Company Logo"
            onError={(e) => {
              console.log("Image load error, falling back to default");
              e.target.src = NavbarLogo; // Fallback to default logo if image fails to load
            }}
          />
        </div>
        <HamburgerMenu />
        <div className="headerSearchSection">
          <div className="headerTxt">
            <h3 id="companyNameId">
              {currentSettings ? currentSettings.appname : "Incubator"}
            </h3>
          </div>
        </div>
        <div className="notificationDiv" id="notificationId">
          <NotificationWidget />
        </div>
        {/* Profile section */}
        <div className="profileSection">
          <div className="profileWrapper">
            <h2 className="" id="adminNameId">
              {userData
                ? `${userData?.firstname || ""} ${userData?.lastname || ""}`
                : "placeholder"}
            </h2>
            <div
              className="profileDetails"
              onClick={handleProfile}
              id="profileDetailsId"
            >
              <img
                src={userData?.imageUrl || ProfileImg}
                alt="Profile"
                className="profImgClass"
                id="profileImgId"
                onError={(e) => {
                  e.target.src = ProfileImg; // Fallback to default profile image
                }}
              />
              <span className="arrowIcon">
                <img
                  src={arrowIcon}
                  alt="Toggle menu"
                  onClick={handleProfile}
                />
              </span>
            </div>
          </div>
          {isProfileClicked && (
            <LogOutWidget
              setIsProfileClicked={setIsProfileClicked}
              onClose={handleProfile}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TopNavBar;
