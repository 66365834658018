import axios from "axios";

export const fetchSettingsUtil = async () => {
  const settingRes = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/settings`,
    {}
  );
  console.log("settingRes Inside API call", settingRes?.data?.body[0][0])
  return settingRes?.data?.body[0][0];
};

export const updateSettingUtil = async () => {

}

export const getFileUrl = `${process.env.REACT_APP_API_URL}/v1/files/`;
