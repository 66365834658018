import React, { useState, useRef } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import "./AddStaffPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  dropHandler,
  dragOverHandler,
} from "../../../utils/imageDragDropHandlers";
import { fetchFilters } from "../../../utils/filtersOperations";
import { useQuery } from "react-query";
import { fetchAllData } from "../../../utils/fetchData";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";
const apiURL = process.env.REACT_APP_API_URL;

function AddStaffPage() {
  const meetingEndpoint = "/v1/rooms";
  const filtersEndpoint = "designations";
  const [selectedFile, setSelectedFile] = useState(null);
  const [newStaffData, setNewStaffData] = useState({});
  const [designations, setDesignations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const fileInputRef = useRef(null);
  const {} = useQuery(
    ["designationsFilters", filtersEndpoint],
    () => fetchFilters(filtersEndpoint),
    {
      onSuccess: (data) => {
        setDesignations(data.map((designation) => designation.title));
      },
    }
  );

  const { data, isLoading } = useQuery(
    ["Meeting", meetingEndpoint],
    () => fetchAllData(meetingEndpoint),
    {
      onSuccess: (data) => {
        // Perform actions on successful data retrieval
        const roomNames = [];

        data.forEach((room) => {
          roomNames.push({ roomName: room.title, roomId: room.id });
        });

        setRooms(roomNames);
        console.log("meeting room data", roomNames);
        // Additional logic here if needed
      },
    }
  );

  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
  };

  const handleOnInputChange = (input) => {
    const { name, value } = input.target;
    setNewStaffData({ ...newStaffData, [name]: value });
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleNewStaffAdd = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Please select an image");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);

      const imageUploadResponse = await axios.post(
        `${apiURL}/v1/files/`,
        imageFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const imagePath = imageUploadResponse.data.body[0].filename;
      const formData = new FormData();

      formData.append("image", imagePath);
      formData.append(
        "name",
        newStaffData.firstName + " " + newStaffData.lastName
      );
      formData.append("email", newStaffData.email);
      formData.append("designation", newStaffData.designation);
      formData.append("incharge", Number(newStaffData?.incharge));
      formData.append("description", newStaffData.description);

      const response = await axios.post(`${apiURL}/v1/staffs`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      navigate("/staff");
      // window.location.reload();
      console.log("success:", response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="addNewRoomWapper">
            <NavigateBack
              fill={"#3384b1"}
              onClick={() => (window.location.href = "/staff")}
            />
            <div className="addNewFormWrapper">
              <form
                onSubmit={handleNewStaffAdd}
                action=""
                className="addNewRoomForm"
              >
                <h2>Add Staff</h2>
                <AsteriskText />
                <div
                  className="fileInput"
                  onDragOver={dragOverHandler}
                  onDrop={(event) => {
                    dropHandler(event, setSelectedFile);
                  }}
                >
                  <label htmlFor="files">
                    Select File <Asterisk />
                  </label>
                  <p
                    className={
                      selectedFile ? "showFileDetails" : "hideFileDetails"
                    }
                    style={{ display: selectedFile ? "block" : "none" }}
                  >
                    {selectedFile ? (
                      <>
                        {selectedFile.name}
                        <span
                          className="removeImage"
                          onClick={handleImageRemove}
                        >
                          <CloseIcon />
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <input
                    type="file"
                    name=""
                    id="files"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                <div className="addNewFormInputWrapper">
                  <label htmlFor="empName">
                    First Name
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter First name"
                    onChange={handleOnInputChange}
                    required
                    // pattern="[^\s]+" title="Please enter one word with no spaces"
                  />
                </div>
                {/* added employee first name and last name //new design changes */}
                <div className="addNewFormInputWrapper">
                  <label htmlFor="empName">
                    Last Name
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Enter Last name"
                    onChange={handleOnInputChange}
                    required
                    // pattern="[^\s]+" title="Please enter one word with no spaces"
                  />
                </div>
                {/* email field*/}
                <div className="addNewFormInputWrapper">
                  <label htmlFor="emaill">
                    Email
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    onChange={handleOnInputChange}
                    required
                  />
                </div>

                <div className="addNewFormInputWrapper">
                  <label htmlFor="empDesig">
                    Employee Designation (optional)
                    {/* <Asterisk /> */}
                  </label>
                  <br />
                  <select
                    onChange={handleOnInputChange}
                    name="designation"
                    // required
                  >
                    <option value="">Select One</option>
                    {console.log(designations)}
                    {designations.map((designation, index) => (
                      <option key={index} value={designation}>
                        {designation}
                      </option>
                    ))}
                  </select>
                </div>
                {/* dropdown */}
                <div className="addNewFormInputWrapper">
                  <label htmlFor="empDesig">
                    In-Charge (Optional)
                    {/* <Asterisk /> */}
                  </label>
                  <br />
                  <select
                    onChange={handleOnInputChange}
                    name="incharge"
                    // required
                  >
                    <option value="">Select One</option>
                    {rooms.map((room, index) => (
                      <option key={index} value={room.roomId}>
                        {room.roomName.split("|")[0]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="addNewFormInputWrapper descheight">
                  <label htmlFor="description">
                    Description
                    <Asterisk />
                  </label>
                  <br />
                  <textarea
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                    onChange={handleOnInputChange}
                  ></textarea>
                </div>
                <div className="addBtnWrapper">
                  <button type="submit">Add</button>
                </div>
              </form>
            </div>
          </div>
          {/* Content of the page */}
        </div>
      </div>
    </>
  );
}

export default AddStaffPage;
