import React, { useState, useEffect } from "react";
import "./../Subadmin.css";
import "./ManageAdmins.css";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import MoreIcon from "../../../assets/png/More.png";
import SearchIcon from "../../../assets/svg/search.svg";
import EditIcon from "../../../assets/svg/Editicon.svg";
import DeleteIcon from "../../../assets/svg/DeleteR.svg";
import CrossIcon from "../../../assets/png/crossIcon.png";
import UserIcon from "../../../assets/svg/User.svg";
import PhotoIcon from "../../../assets/png/photoIcon.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
import axios from "axios";
import { toast } from "react-toastify";

function ManageAdmins() {
  const [usersData, setUsersData] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    visibility: true,  
  });
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const [imagePath,setImagePath]=useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedFilter, setDebouncedFilter] = useState("");

  useEffect(() => {
    fetchAllAdmins();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedFilter(globalFilter);  
    },300);  

    return () => clearTimeout(timer);
  }, [globalFilter]);

  const fetchAllAdmins = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/users/all?usertype=admin`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setUsersData(response.data.body);
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
    }
  };

  const uploadImages=async(profileImage)=>{
    const formData=new FormData();
    formData.append("image",profileImage);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/files/multiple`, formData, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setImagePath(response.data.body[0].filename);
      toast.success("Profile image uploaded successfully");
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
    }
  };

  const createAdmin = async () => {
    try {
      if(!userData.email){
        toast.error("Email is required");
        return
      }
      const bodyData = {
        email: userData.email,
        firstname: userData.firstname,
        lastname: userData.lastname,
        usertype: "admin",
        visibility: userData.visibility ? "active" : "inactive",
      };
  
      if (userData.phone) {
        bodyData.phone = String(userData.phone); 
      }
      const response=await fetch(`${process.env.REACT_APP_API_URL}/v1/users/addAdmin`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      const data=await response.json();
      if(data.status!==200){
        toast.error(data.message);
        return false
      }
      fetchAllAdmins();
      toast.success("Admin created successfully");
      return true
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
      return false
    }
  };

  const updateAdmin = async (id) => {  
    try {
      const bodyData = {
        email: userData.email,
        firstname: userData.firstname,
        lastname: userData.lastname,
        usertype: "admin",
        visibility: userData.visibility ? "active" : "inactive",
      };
  
      if (userData.phone) {
        bodyData.phone = String(userData.phone); 
      }
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/users/${id}`, bodyData, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      console.log('response',response)
      if(response.status!==200){
        toast.error(response.data.message);
        return false
      }
      fetchAllAdmins();
      toast.success("Admin updated successfully");
      return true
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
      return false
    }
  };

  const deleteAdmin = async () => {
    if (selectedAdmin) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/users/${selectedAdmin.id}`, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        fetchAllAdmins();
        setDeleteDialog(false);  
      } catch (error) {
        toast.error(error.response.data.message || "Something went wrong");
      }
    }
  };

  const openEditDialog = (adminData = null) => {
    setSelectedAdmin(adminData);

    if (adminData) {
      setUserData({
        firstname: adminData.firstname || "",
        lastname: adminData.lastname || "",
        email: adminData.email||"",
        phone: adminData.phone?adminData.phone:"",
        visibility: adminData.visibility === "active" ? true : false,
      });
    } else {
      setUserData({ firstname: "", lastname: "", email: "", phone: "", visibility: true });
    }

    setModalVisible(true);
  };

  const closeDialog = () => {
    setModalVisible(false);
  };

  const handleSave = async() => {
    let response
    if (selectedAdmin) {
      response =await updateAdmin(selectedAdmin.id);  
    } else {
      response =await createAdmin();  
    } 
    if(!response){
      return
    }

    setModalVisible(false);
    setSelectedAdmin(null);
    setUserData({ firstname: "", lastname: "", email: "", phone: "", visibility: true });
    setProfileImage(null);
    setProfileImagePreview(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImagePreview(URL.createObjectURL(file));
      setProfileImage(file);
    }
  };

  const handleToggle = () => {
    setUserData((prev) => ({ ...prev, visibility: !prev.visibility }));
  };

  const handleDeleteAdmin=(rowData)=>{
    if (!deleteDialog) { 
      setSelectedAdmin(rowData);
      setDeleteDialog(true);
    }
    // setSelectedAdmin(rowData);
    // setDeleteDialog(true);
  }

  const handleActions = (rowData) => {
    return (
      <div className="button-container">
        <button
          className="sub-edit-button-container"
          onClick={() => openEditDialog(rowData)}
        >
          <img src={EditIcon} alt="edit icon" className="button-icon" />
          <h1 className="button-container-text">Edit</h1>
        </button>
        <button
          className="sub-delete-button-container"
          onClick={()=>handleDeleteAdmin(rowData)}
          disabled={deleteDialog} 
        >
          <img src={DeleteIcon} alt="delete icon" className="button-icon" />
          <h1 className="button-container-text">Delete</h1>
        </button>
      </div>
    );
  };

  const handleCloseDeleteDialog=()=>{setDeleteDialog(false)}
  const handleSearchChange = (e) => {
    setGlobalFilter(e.target.value);  
  };

  const onPageChange = (event) => {
    setLimit(event.rows);
    setOffset(event.first);
  };

  return (
    <div className="container">
      <Sidebar className="sidebar" />
      <div className="content">
        <TopNavBar />
        <div className="manage-admins">
          <h1 className="manage-admins-heading">Current Admins</h1>
          <div className="add-admin-container">
            <button
              className="addAdminButton"
              onClick={() => openEditDialog(null)}
            >
              <span>
                <img src={MoreIcon} alt="add more icon" />
              </span>
              Add Admin
            </button>
            <div className="search-container">
              <input
                className="search-input"
                type="text"
                value={globalFilter}
                onChange={handleSearchChange}
                placeholder="Search by name"
              />
              <img src={SearchIcon} alt="search icon" />
            </div>
          </div>
          <DataTable
            value={usersData}
            className="admin-header-table"
            paginator
            rows={limit}
            rowsPerPageOptions={[5, 10, 15, 20]}
            onPageChange={onPageChange}
            tableStyle={{ height: "10px" }}
            globalFilter={debouncedFilter}
          >
            <Column field="name" header="FullName" body={rowData => `${rowData.firstname} ${rowData.lastname}`} sortable />
            <Column field="email" header="Email" sortable />
            <Column field="phone" header="Phone" sortable body={rowData => rowData.phone || "-"} />
            <Column field="action" header="Action" body={handleActions} />
          </DataTable>
        </div>
      </div>

      {isModalVisible && (
        <div className="profileFormWrapper">
          <section className="formSection">
            <div className="profileFormHeaderWrapper">
              <div className="profileFormHeader">
                <div className="profileTag active">
                  <img src={UserIcon} alt="User Icon" />
                  <h2>Profile</h2>
                </div>
              </div>
              <span onClick={closeDialog}>
                <img src={CrossIcon} alt="Close" />
              </span>
            </div>
            <div className="formWrapper">
              <div className="sub-formProfileImgWrapper">
                <div className="sub-formProfileImg">
                  {profileImagePreview ? (
                    <>
                      <img
                        src={profileImagePreview}
                        alt="Profile"
                        className="sub-profileImage"
                      />
                      <label htmlFor="imageInput" className="editProfileIcon">
                        <img
                          src={PhotoIcon}
                          alt="Edit Profile"
                          className="edit-icon"
                        />
                      </label>
                    </>
                  ) : (
                    <label
                      htmlFor="imageInput"
                      className="defaultProfileWrapper"
                    >
                      <img
                        src={PhotoIcon}
                        alt="Profile"
                        className="default-formProfileImg"
                      />
                      <p>Add Photo</p>
                    </label>
                  )}
                </div>
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>

              <form onSubmit={(e) => e.preventDefault()} style={{ width: "100%" }}>
                <div className="formFlexClass">
                  <div className="sub-formInputWrapper">
                    <label htmlFor="fName">
                      First Name{userData.firstname === "" ? "*" : ""}
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="fName"
                      onChange={handleInputChange}
                      value={userData.firstname}
                      required={userData.firstname === "" ? true : false}
                    />
                  </div>
                  <div className="sub-formInputWrapper">
                    <label htmlFor="lName">
                      Last Name{userData.lastname === "" ? "*" : ""}
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lName"
                      onChange={handleInputChange}
                      value={userData.lastname}
                      required={userData.lastname === "" ? true : false}
                    />
                  </div>
                  <div className="sub-formInputWrapper">
                    <label htmlFor="email">
                      Email{userData.email === "" ? "*" : ""}
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleInputChange}
                      value={userData.email}
                      required={userData.email === "" ? true : false}
                    />
                  </div>
                  <div className="sub-formInputWrapper">
                    <label htmlFor="phNum">Phone number</label>
                    <input
                      type="number"
                      name="phone"
                      id="phNum"
                      onChange={handleInputChange}
                      value={userData.phone}
                      required
                    />
                  </div>
                </div>
                <div className="toggleContainer">
                  <ToggleSwitch
                    className="toggleSwitch"
                    defaultChecked={userData?.visibility}
                    onChange={handleToggle}
                  />
                  <h1>Visibility</h1>
                </div>

                <div className="buttonWrapper">
                  <button type="button" onClick={handleSave}>
                    Save
                  </button>
                  <button type="button" onClick={closeDialog}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}

      {deleteDialog && (
        <>
          <div className="deleteDialogWrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                cursor: "pointer",
              }}
              onClick={handleCloseDeleteDialog}
            >
              <img
                src={CrossIcon}
                alt="Close"
                className="deleteIcon"
                onClick={closeDialog}
              />
            </div>
            <div className="deleteDialogHeader">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div className="delete-button-container-icon">
                  <img
                    src={DeleteIcon}
                    alt="delete icon"
                    className="delete-button-icon"
                  />
                </div>
                <h1 className="deleteDialogHeaderTitle">Delete Admin</h1>
              </div>
            </div>
            <div className="deleteDialogContent">
              <p>Do you really want to remove admin rights from this member?</p>
              {/* <textarea
                type="text"
                className="deleteReasonInput"
                placeholder="Enter your reason..."
              /> */}
            </div>

            <div className="deleteDialogButtons">
              <button
                type="button"
                className="deleteButton"
                onClick={deleteAdmin}
              >
                Delete
              </button>
              <button
                type="button"
                className="cancelButton"
                onClick={handleCloseDeleteDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ManageAdmins;
