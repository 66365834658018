import React, { useEffect, useState, useRef } from "react";
import "./NotificationWidget.css";
import BellIcon from "../../../src/assets/png/BellIcon.png";
import { ReactComponent as NotificationWatch } from "../../../src/assets/svg/notificationWatch.svg";
import axios from "axios";
import { io } from "socket.io-client";
import NotificationsView from "./NotificationsView";

function NotificationWidget() {
  const [isClicked, setIsClicked] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationNumber, setNotificationNumber] = useState(0);

  const getAdminNotifications = async () => {
    const adminDetails = localStorage.getItem("userData");
    const parsedData = JSON.parse(adminDetails);
    try {
      const adminNotificationsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/notifications/${parsedData?.id}`
      );
      if (adminNotificationsResponse.status === 200) {
        const _notifications = adminNotificationsResponse.data;
        _notifications.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotifications(_notifications);
        setNotificationNumber(
          _notifications?.filter((n) => !n.isRead).length || 0
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const adminDetails = localStorage.getItem("userData");
    const parsedAdminDetails = JSON.parse(adminDetails);
    console.log(`${process.env.REACT_APP_WEBSOCKET_URL}?userId=${parsedAdminDetails?.id}`)
    const socket = io(
      `${process.env.REACT_APP_WEBSOCKET_URL}?userId=${parsedAdminDetails?.id}`
    );
    socket.on("receive_notification", (data) => {
      console.log("entered")
      setNotifications((prevNotifications) => [data, ...prevNotifications]);
      setNotificationNumber(
        (prevNotificationNumber) => prevNotificationNumber + 1
      );
    });
    getAdminNotifications();
    return () => {
      socket.off("receive_notification");
    };
  }, []);

  const handleNotifications = () => {
    setIsClicked((prev) => !prev);
  };

  return (
    <div className="notif-container">
      <div className="bellIconWrapper" onClick={handleNotifications}>
        <img src={BellIcon} alt="" id="notifImg" />
        <div className="notificationNumberDiv">
          <span>{notificationNumber}</span>
        </div>
      </div>

      {isClicked && (
        <NotificationsView
          notifications={notifications}
          setNotifications={setNotifications}
          setNotificationNumber={setNotificationNumber}
        />
      )}
    </div>
  );
}

export default NotificationWidget;