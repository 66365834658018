import React, { useState, useEffect } from "react";
import "./Calender.css";

const Calendar = ({ onDateSelect, selectedDate, beforeYears = 10, afterYears = 10 }) => {
  const currentDate = new Date();
  const [date, setDate] = useState(selectedDate || currentDate);
  const [highlightedDate, setHighlightedDate] = useState(selectedDate?.getDate() || null);

  const handleDateClick = (day) => {
    const newSelectedDate = new Date(date.getFullYear(), date.getMonth(), day);
    setHighlightedDate(day);
    onDateSelect(newSelectedDate);
  };

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
      setHighlightedDate(selectedDate.getDate());
    }
  }, [selectedDate]);

  const getYearsList = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    console.log("currentYear", currentYear,beforeYears);
    for (let i = currentYear - beforeYears; i <= currentYear + afterYears; i++) {
      years.push(i);
    }
    console.log("years", years);
    return years;
  };

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const handlePrevMonth = () => setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  const handleNextMonth = () => setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  const handleYearChange = (event) => setDate(new Date(event.target.value, date.getMonth(), 1));
  const handleMonthChange = (event) => setDate(new Date(date.getFullYear(), event.target.value, 1));

  const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();
  const getFirstDayOfMonth = (month, year) => new Date(year, month - 1, 1).getDay();

  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = getDaysInMonth(month + 1, year);
  const firstDay = getFirstDayOfMonth(month + 1, year);

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const emptyDays = Array(firstDay).fill(null);

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button onClick={handlePrevMonth}>&lt;</button>
        <select value={date.getFullYear()} onChange={handleYearChange}>
          {getYearsList().map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <select value={date.getMonth()} onChange={handleMonthChange}>
          {months.map((month, index) => (
            <option key={month} value={index}>{month}</option>
          ))}
        </select>
        <button onClick={handleNextMonth}>&gt;</button>
      </div>

      <div className="calendar-days">
        {emptyDays.map((_, idx) => (
          <div key={`empty-${idx}`} className="calendar-day empty"></div>
        ))}
        {days.map((day) => (
          <div
            key={day}
            className={`calendar-day ${day === highlightedDate ? "selected" : ""}`}
            onClick={() => handleDateClick(day)}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
