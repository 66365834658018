import React, { useRef, useState } from "react";
import axios from "axios";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import { useNavigate } from "react-router-dom";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";
function AddNewNews() {
  const apiURL = process.env.REACT_APP_API_URL;
  const [selectedFile, setSelectedFile] = useState(null);
  const [newsData, setNewsData] = useState({
    newsTitle: "",
    // news: "",
    description: "",
    priority: false,
  });
  const fileInputRef = useRef(null);
  // navigate function
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewsData({ ...newsData, [name]: value });
    console.log("handle change connected");
  };

  // Logic for file input
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
  };
  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleNewsSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.warn("Please select an image");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);

      const imageUploadResponse = await axios.post(
        `${apiURL}/v1/files/`,
        imageFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("add news file upload", imageUploadResponse.status);
      const imagePath = imageUploadResponse.data.body[0].filename;

      const formData = new FormData();
      formData.append("files", imagePath);
      formData.append("title", newsData.newsTitle);
      formData.append("news", newsData.news);
      formData.append("description", newsData.description);
      formData.append("priority", newsData?.priority);
      console.log(formData);
      const response = await axios.post(`${apiURL}/v1/news`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      navigate("/news");
      window.location.reload();
      console.log("success:", response.data);
    } catch (error) {
      console.error("Error creating news:", error.message);
    }
  };

  const onToggle = (e) => {
    const isOn = newsData?.priority;
    setNewsData({
      ...newsData,
      priority: !isOn,
    });
  };

  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="addNewRoomWapper">
            <NavigateBack
              fill={"#3384b1"}
              onClick={() => (window.location.href = "/news")}
            />
            <div className="addNewFormWrapper">
              <form
                action=""
                className="addNewRoomForm"
                onSubmit={handleNewsSubmit}
              >
                <h2>Add News</h2>
                <AsteriskText />
                <div
                  className="fileInput"
                  onDragOver={dragOverHandler}
                  onDrop={(event) => {
                    dropHandler(event, setSelectedFile);
                  }}
                >
                  <label htmlFor="files">Select Files</label>
                  <div className="fileNames" style={{ display: "flex" }}>
                    <p
                      className={
                        selectedFile ? "showFileDetails" : "hideFileDetails"
                      }
                    >
                      {selectedFile ? (
                        <>
                          {selectedFile.name}
                          <span
                            className="removeImage"
                            onClick={handleImageRemove}
                          >
                            <CloseIcon />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  {/* <p className={selectedFile ? "showFileDetails" : "hideFileDetails"}>{selectedFile ? selectedFile.name : ""}</p> */}
                  <input
                    type="file"
                    name="files"
                    id="files"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                <div className="addNewFormInputWrapper">
                  <label htmlFor="newsTitle">
                    News Title
                    <Asterisk />
                    <span style={{fontSize: "10px",fontFamily: "Nunito"}}>(should be more than 4 characters)</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="newsTitle"
                    placeholder="Enter news title"
                    value={newsData.newsTitle}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {/* <div className="addNewFormInputWrapper">
                  <label htmlFor="news">
                    News
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="news"
                    placeholder="Enter news"
                    onChange={handleInputChange}
                    value={newsData.news}
                    required
                  />
                </div> */}

                <div className="addNewFormInputWrapper descheight">
                  <label htmlFor="description">
                    Description <Asterisk />
                  </label>
                  <br />
                  <textarea
                    type="text"
                    name="description"
                    placeholder="Enter the description"
                    value={newsData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="setQuickBookingSection">
                  <div className="leftClass">
                    <h4>Set Priority</h4>
                  </div>
                  <div className="rightClass">
                    <input
                      type="checkbox"
                      name=""
                      id="toggleQuickBooking"
                      onChange={onToggle}
                    />
                    <label htmlFor="toggleQuickBooking">
                      <div className="toggleText">
                        <span>ON</span>
                        <span>OFF</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="addBtnWrapper">
                  <button type="submit">Add</button>
                </div>
              </form>
            </div>
          </div>
          {/* Content of the page */}
        </div>
      </div>
    </>
  );
}

export default AddNewNews;
