import React, { useEffect, useState, useRef } from "react";
import "./ProfileForm.css";
import ProfileFormImg from "../../../src/assets/png/ProfileFormImg.png";
import ProfileSelectionIcon from "../../../src/assets/svg/profilecamera.svg";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const apiURL = process.env.REACT_APP_API_URL;

function ProfileForm({ onClose }) {
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`${apiURL}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = data.body[0];

      if (userData.image.includes("http")) {
        userData.imageUrl = userData.image;
      } else {
        const imageResponse = await axios.get(
          `${apiURL}/v1/files/${userData.image}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([imageResponse.data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        userData.imageUrl = url;
      }

      console.log("userData", userData);

      return userData;
    } catch (error) {
      console.log(error);
    }
  };

  const [userData, setUserData] = useState({});
  const [tempFlag, setTempFlag] = useState(true);
  const [profileImage, setProfileImage] = useState(ProfileFormImg);
  const { data, isLoading } = useQuery("userData", fetchUserDetails, {
    onSuccess: (data) => {
      setUserData(data);
      console.log("userData", userData);
      if (tempFlag && data?.imageUrl) {
        setTempFlag(false);
        setProfileImage(data.imageUrl);
      }
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);
  
  // React Image Crop states
  const [showCropper, setShowCropper] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  // Function to center crop with a 1:1 aspect ratio
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setShowCropper(true);
      });
      reader.readAsDataURL(file);
    }
  };

  // When the image loads, set up the initial crop
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1)); // 1 = square aspect ratio
  };

  // Function to apply crop and convert to file
  const handleCropComplete = () => {
    if (!completedCrop || !imgRef.current) return;

    const canvas = document.createElement('canvas');
    const crop = completedCrop;
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Set desired dimensions for cropped image
    const outputWidth = 200;
    const outputHeight = 200;
    
    canvas.width = outputWidth;
    canvas.height = outputHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      outputWidth,
      outputHeight
    );

    // Convert to data URL and File
    const base64Image = canvas.toDataURL('image/jpeg');
    setProfileImage(base64Image);

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        const file = new File([blob], 'cropped-profile.jpg', { type: 'image/jpeg' });
        setSelectedFile(file);
        setShowCropper(false);
      },
      'image/jpeg',
      0.95
    );
  };

  // Function to cancel cropping
  const handleCropCancel = () => {
    setShowCropper(false);
    setImageSrc(null);
    setCrop(undefined);
    setCompletedCrop(null);
  };

  const handleOnInputChange = (input) => {
    const { name, value } = input.target;
    setUserData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleOnSubmitForm = async (e) => {
    e.preventDefault();
    try {
      let imagePath = null;
      const token = localStorage.getItem("token");

      if (selectedFile) {
        const imageFormData = new FormData();
        imageFormData.append("file", selectedFile);
        const imageUploadResponse = await axios.post(
          `${apiURL}/v1/files`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        imagePath = imageUploadResponse.data.body[0].filename;
      }

      console.log("imagePath", imagePath);
      const formData = new FormData();
      formData.append("image", imagePath || userData.image);
      formData.append("firstname", userData.firstname);
      formData.append("lastname", userData.lastname);
      formData.append("email", userData.email);
      
      console.log("Here manage account", userData);
      const response = await axios.put(
        `${apiURL}/v1/users/${userData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );

      onClose();
      window.location.reload();
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <div className="formWrapper">
        <div className="formProfileImgWrapper">
          <div className="formProfileImg">
            <img src={profileImage} alt="Profile" className="profileImage" />
            <label htmlFor="imageInput">
              <img
                src={ProfileSelectionIcon}
                alt="Profile Selection"
                className="overlayIcon"
              />
            </label>
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="loading">Loading....</div>
      </div>
    );
  } else {
    return (
      <div className="formWrapper">
        {showCropper ? (
          <div className="cropperContainer">
            <h3>Crop Your Profile Image</h3>
            <p>Please crop your image to a square for best results</p>
            
            {imageSrc && (
              <div className="reactCropWrapper" style={{ maxWidth: '400px', maxHeight: '400px', margin: '0 auto', overflow: 'hidden' }}>
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={1} // Square aspect ratio
                  circularCrop
                >
                  <img
                    ref={imgRef}
                    src={imageSrc}
                    onLoad={onImageLoad}
                    style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'contain' }}
                    alt="Crop preview"
                  />
                </ReactCrop>
              </div>
            )}
            
            <div className="cropperButtons">
              <button 
                type="button" 
                onClick={handleCropComplete}
                disabled={!completedCrop?.width || !completedCrop?.height}
              >
                Apply Crop
              </button>
              <button type="button" onClick={handleCropCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <>
            <div className="formProfileImgWrapper">
              <div className="formProfileImg">
                <img src={profileImage} alt="Profile" className="profileImage" />
                <label htmlFor="imageInput">
                  <img
                    src={ProfileSelectionIcon}
                    alt="Profile Selection"
                    className="overlayIcon"
                  />
                </label>
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <form onSubmit={handleOnSubmitForm} action="">
              <div className="formFlexClass">
                <div className="formInputWrapper">
                  <label htmlFor="fName">First Name</label>
                  <input
                    type="text"
                    name="firstname"
                    id="fName"
                    onChange={handleOnInputChange}
                    value={userData?.firstname}
                    required
                  />
                </div>
                <div className="formInputWrapper">
                  <label htmlFor="lName">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    id="lName"
                    onChange={handleOnInputChange}
                    value={userData?.lastname}
                    required
                  />
                </div>
              </div>
              <div className="formFlexClass">
                <div className="formInputWrapper">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleOnInputChange}
                    value={userData?.email}
                    disabled
                    className="disabledClass"
                    required
                  />
                </div>
                <div className="formInputWrapper">
                  <label htmlFor="phNum">Phone number</label>
                  <input
                    type="number"
                    name="phone"
                    id="phNum"
                    onChange={handleOnInputChange}
                    value={userData?.phone}
                    disabled
                    className="disabledClass"
                  />
                </div>
              </div>
              <div className="buttonWrapper">
                <button type="submit">Save</button>
                <button onClick={onClose}>Cancel</button>
              </div>
            </form>
          </>
        )}
      </div>
    );
  }
}

export default ProfileForm;