import React, { useState ,useEffect} from "react";
import ".././Subadmin.css";
import "./AuditReports.css";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import SearchIcon from "../../../assets/svg/search.svg";
import CalendarIcon from "../../../assets/svg/CalenderIcon.svg";
import DownloadIcon from "../../../assets/svg/DownloadIcon.svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Calendar from "../../../components/Calender";
import axios from "axios";
import { toast } from "react-toastify";

function AuditReports() {
  const [auditReports, setAuditReports] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [globalFilter,setGlobalFilter]=useState("")
  const [debouncedFilter,setDebouncedFilter]=useState("")

  useEffect(()=>{
    fetchAllAudits()
  },[selectedDate])

  useEffect(()=>{
    const debounceFun=setTimeout(()=>{
      setDebouncedFilter(globalFilter)
    },300)

   return() =>clearTimeout(debounceFun) 
  },[globalFilter])

  const handleDateSelect = (newDate) => {
    setSelectedDate(newDate);
    setShowCalendar(false);
  };

  const formatDate = (rowData) =>{
    return (
      <>
      {rowData.actionCreatedDate.split("T")[0]}{" "}{rowData.actionCreatedTime}
      </>
    )
  }

  const getFormattedMonthYear = (date) => {
    const currentDate = date ? new Date(date) : new Date();
    return new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      currentDate
    );
  };

  const fetchAllAudits = async () => {
    try {
      const month = selectedDate ? selectedDate.getMonth() + 1 : new Date().getMonth() + 1;
      const year = selectedDate ? selectedDate.getFullYear() : new Date().getFullYear();
  
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/audits/?month=${month}&year=${year}`,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );  
      if (Array.isArray(response.data)) {
        setAuditReports(response.data);
      } else {
        setAuditReports([]); 
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error(error.message || "Something went wrong");
      setAuditReports([]); 
    }
  };
  
  const exportToCSV = () => {
    const csvData = auditReports.map((user) => ({
      Date: user.actionCreatedDate.split("T")[0] + " " + user.actionCreatedTime,
      actionType: user.actionType,
      actionPerformedBy: user.actionPerformedBy,
      affectedEntityName: user.affectedEntityName,
      action: user.action,
    }));

    if (csvData.length === 0) {
      toast.error("No data to export!")
      return;
    }

    const csvHeader = Object.keys(csvData[0]).join(",");
    const csvRows = csvData
      .map((row) => Object.values(row).join(","))
      .join("\n");
    const csvContent = `${csvHeader}\n${csvRows}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "complaint_reports.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Data exported to CSV!")
  };
  

  const handleSearchChange = (e) => {
    setGlobalFilter(e.target.value);  
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="content">
        <TopNavBar />
        <div className="audit-reports-container">
          <h1 className="audit-reports-header">Audit Trail Reports</h1>
          <div className="search-calender-container">
            <div className="search-container">
              <input
                className="search-input"
                type="text"
                placeholder="Search by name"
                onChange={handleSearchChange}
                value={globalFilter}
              />
              <img src={SearchIcon} alt="search icon" />
            </div>

            <div className="search-sub-calender-container">
              <div className="calendar-container">
                <button
                  className="calendar-icon-button-container"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <h1 className="selected-month">
                    {getFormattedMonthYear(selectedDate)}
                  </h1>
                  <img src={CalendarIcon} alt="Calendar" />
                </button>

                {showCalendar && (
                  <div className="calendar-popup">
                    <Calendar
                      onDateSelect={handleDateSelect}
                      selectedDate={selectedDate}
                      beforeYears={5}
                      afterYears={0}
                    />
                  </div>
                )}
              </div>
                <button className="download-button-container" onClick={exportToCSV}>
                  <img src={DownloadIcon} alt="Download" />
                  <h1>Download</h1>
                </button>
            </div>
          </div>
          <DataTable
            value={auditReports}
            className="audit-reports-header-table"
            rows={5}
            emptyMessage="No records found"
            paginator
            rowsPerPageOptions={[5, 10]}
            globalFilter={globalFilter}
          >
            <Column
              field="DateandTime"
              header="Date and Time"
              body={formatDate}
              sortable
            />
            <Column field="actionType" header="Action Type" sortable />
            <Column field="actionPerformedBy" header="Performed By" sortable />
            <Column field="affectedEntityName" header="Affected Entity" sortable />
            <Column field="action" header="Message" />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default AuditReports;
