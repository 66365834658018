import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onDelete,
  itemName = "item",
  loading = false,
  title = "Confirm Deletion",
  description = "Are you sure you want to delete this",
  cancelButtonText = "Cancel",
  deleteButtonText = "Delete",
}) => {
  const themeColor = "#3384b1";
  const textColor = "#000000";

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: textColor,
          fontWeight: 600,
          fontFamily: "Nunito",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: textColor,
            fontFamily: "Nunito",
          }}
        >
          {`${description} ${itemName}? This action cannot be undone.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2, gap: 1 }}>
        <Button
          onClick={onClose}
          disabled={loading}
          color="primary"
          variant="outlined"
          sx={{
            color: themeColor,
            borderColor: themeColor,
            fontFamily: "Nunito",
            "&:hover": {
              borderColor: themeColor,
              backgroundColor: "rgba(51, 132, 177, 0.04)",
            },
            "&:disabled": {
              borderColor: "rgba(51, 132, 177, 0.4)",
              color: "rgba(51, 132, 177, 0.4)",
            },
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={onDelete}
          disabled={loading}
          color="error"
          variant="contained"
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <DeleteIcon />
            )
          }
          sx={{
            backgroundColor: themeColor,
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#2b6f95",
            },
            "&:disabled": {
              backgroundColor: "rgba(51, 132, 177, 0.4)",
              color: "#ffffff",
            },
          }}
        >
          {loading ? "Deleting..." : deleteButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
