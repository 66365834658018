import React, { useRef, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";

function EditNews() {
  const apiURL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const [editNews, setEditNews] = useState(location.state);
  console.log("editNews", editNews);
  console.log(editNews);
  const [selectedFile, setSelectedFile] = useState(location.state.files);
  const fileInputRef = useRef(null);
  const [fileChange, setFileChange] = useState(false);

  console.log(editNews);

  // navigate function
  const navigate = useNavigate();

  //  // use states for input
  //  const [editNews, setEditNews] = useState({
  //   newsTitle: newsData.title,
  //   news: newsData.news,
  //   description: newsData.description,
  //  });

  // handle change for all inputs
  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditNews((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("handle change connected");
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
    setFileChange(true);
  };

  // form edit function
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    console.log("edit form connected");

    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);
      let imagePath;
      console.log(imageFormData);

      if (fileChange && selectedFile) {
        const imageUploadResponse = await axios.post(
          `${apiURL}/v1/files/`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imagePath = imageUploadResponse.data.body[0].filename;
        console.log("image upload status:", imageUploadResponse);
      }

      //

      const editFormData = new FormData();
      // Append to form data
      editFormData.append("files", imagePath || editNews.files);
      editFormData.append("title", editNews.title);
      editFormData.append("news", editNews.news);
      editFormData.append("description", editNews.description);
      editFormData.append("priority", editNews?.priority);

      const response = await axios.patch(
        `${apiURL}/v1/news/${editNews.id}`,
        editFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      navigate("/news");
      window.location.reload();
      console.log(response);
    } catch (error) {
      console.error("Error submitting edit data:", error);
    }
  };

  const onToggle = (e) => {
    const isOn = editNews?.priority;
    setEditNews({
      ...editNews,
      priority: !isOn,
    });
  };

  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="addNewRoomWapper">
            <NavigateBack
              fill={"#3384b1"}
              onClick={() => (window.location.href = "/news")}
            />
            <div className="addNewFormWrapper">
              <form
                action=""
                className="addNewRoomForm"
                onSubmit={handleEditFormSubmit}
              >
                <h2>Edit News</h2>
                <AsteriskText />
                <div
                  className="fileInput"
                  onDragOver={dragOverHandler}
                  onDrop={(event) => {
                    dropHandler(event, setSelectedFile);
                    setFileChange(true);
                  }}
                >
                  <label htmlFor="files">Select Files</label>
                  <div className="fileNames" style={{ display: "flex" }}>
                    <p
                      className={
                        selectedFile ? "showFileDetails" : "hideFileDetails"
                      }
                    >
                      {selectedFile ? (
                        <>
                          {selectedFile.name || selectedFile}
                          <span
                            className="removeImage"
                            onClick={handleImageRemove}
                          >
                            <CloseIcon />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <input
                    type="file"
                    name=""
                    id="files"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                <div className="addNewFormInputWrapper">
                  <label htmlFor="title">
                    News Title
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="title"
                    onChange={handleEditInputChange}
                    value={editNews.title}
                  />
                </div>
                {/* <div className="addNewFormInputWrapper">
                  <label htmlFor="news">
                    News
                    <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="news"
                    onChange={handleEditInputChange}
                    value={editNews.news}
                  />
                </div> */}
                <div className="addNewFormInputWrapper descheight">
                  <label htmlFor="description">
                    Description
                    <Asterisk />
                  </label>
                  <br />
                  <textarea
                    type="text"
                    name="description"
                    onChange={handleEditInputChange}
                    value={editNews.description}
                  ></textarea>
                </div>
                <div className="setQuickBookingSection">
                  <div className="leftClass">
                    <h4>Set Priority</h4>
                  </div>
                  <div className="rightClass">
                    <input
                      type="checkbox"
                      name=""
                      id="toggleQuickBooking"
                      onChange={onToggle}
                      checked={editNews?.priority}
                    />
                    <label htmlFor="toggleQuickBooking">
                      <div className="toggleText">
                        <span>ON</span>
                        <span>OFF</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="addBtnWrapper">
                  <button type="submit">Save</button>
                </div>
              </form>
            </div>
          </div>
          {/* Content of the page */}
        </div>
      </div>
    </>
  );
}

export default EditNews;
