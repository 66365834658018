import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import "./UiSettings.css";
import uploadImgIcon from "../../../../src/assets/png/Capa_1.png";
import {
  getUserSpecificSettings,
  createSetting,
  updateSetting,
} from "../../../utils/uiSettingsOperations";
import {
  dropHandler,
  dragOverHandler,
} from "../../../utils/imageDragDropHandlers";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { toast } from "react-toastify";
import { useSettings } from "../../../context/settingsContext.js";
import { getFileUrl } from "../../../utils/fetchSettings.js";

function UiSettings() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImageSrc, setUploadedImageSrc] = useState(null);
  const fileInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const { currentSettings, setCurrentSettings } = useSettings();

  // Initialize image from current settings when component loads or settings change
  useEffect(() => {
    if (currentSettings?.image) {
      setUploadedImageSrc(getFileUrl + currentSettings.image);
    }
  }, [currentSettings]);

  console.log("settingRes Inside UI settings", currentSettings);

  const handleFileChange = (event, isFromOnDrop) => {
    let file = isFromOnDrop
      ? event.dataTransfer.files[0]
      : event.target.files[0];

    if (file) {
      console.log("filefilefile", file);
      const imageUrl = URL.createObjectURL(file);
      setUploadedImageSrc(imageUrl);
      setSelectedFile(file);
    }
  };

  const onInputChange = (e) => {
    const { name, value, checked } = e.target;
    setCurrentSettings((prevState) => ({
      ...prevState,
      [name]: name === "quickbook" ? checked : value,
    }));
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    setUploadedImageSrc(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // console.log
    try {
      setIsLoading(true);
      if (currentSettings?.id) {
        const updatedData = await updateSetting(
          currentSettings.id,
          currentSettings,
          selectedFile
        );

        // Update context with the latest data from the server
        if (updatedData) {
          setCurrentSettings(updatedData);
        }

        // Create a custom event to notify other components
        const settingsEvent = new CustomEvent("settingsUpdated", {
          detail: { timestamp: Date.now() },
        });
        window.dispatchEvent(settingsEvent);

        toast.success("Updated Successfully!");
      } else {
        const newData = await createSetting(currentSettings, selectedFile);

        // Update context with the new data including ID
        if (newData) {
          setCurrentSettings(newData);
        }

        // Create a custom event to notify other components
        const settingsEvent = new CustomEvent("settingsUpdated", {
          detail: { timestamp: Date.now() },
        });
        window.dispatchEvent(settingsEvent);

        toast.success("Created Successfully!");
      }
    } catch (error) {
      console.log(error);
      toast.error("There was an error while saving settings");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleOnSubmit} className="flexForm">
          <div
            className="uploadImgSection"
            id="uploadNewLogoId"
            onDrop={(event) => {
              dropHandler(event, setSelectedFile);
              handleFileChange(event, true);
            }}
            onDragOver={dragOverHandler}
          >
            <div className="uploadImgIconWrapper">
              <img src={uploadedImageSrc || uploadImgIcon} alt="Upload" />
            </div>
            <label htmlFor="files">Upload Image</label>
            <p className={selectedFile ? "showFileDetails" : "hideFileDetails"}>
              {selectedFile && (
                <>
                  {selectedFile?.name}
                  <span className="removeImage" onClick={handleImageRemove}>
                    <CloseIcon />
                  </span>
                </>
              )}
            </p>
            <input
              type="file"
              name="image"
              id="files"
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </div>
          <div className="logoTxtClass">
            <input
              name="appname"
              type="text"
              value={currentSettings?.appname || ""}
              onChange={onInputChange}
              id="addEditCompanyNameId"
              required
            />
          </div>
          <div className="inputTxtClass">
            <textarea
              name="description"
              type="text"
              id="addEditDescriptionId"
              value={currentSettings?.description || ""}
              onChange={onInputChange}
              placeholder="Enter your description here"
            ></textarea>
          </div>
          <div className="allowQuickBookingSection" id="allowQuickBookingId">
            <div className="leftTxtClass">
              <h4>Allow Quick Bookings</h4>
            </div>
            <div className="rightCheckboxClass">
              <input
                type="checkbox"
                name="quickbook"
                id="toggleAllowBooking"
                onChange={onInputChange}
                checked={currentSettings?.quickbook || false}
              />
              <label htmlFor="toggleAllowBooking">
                <div className="toggleAllowText">
                  <span>ON</span>
                  <span>OFF</span>
                </div>
              </label>
            </div>
          </div>
          <div className="quickbkngBtnWrapper">
            <button type="submit">Save</button>
          </div>
        </form>
      )}
    </>
  );
}

export default UiSettings;
