import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { fetchSettingsUtil } from "../utils/fetchSettings";

export const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const [currentSettings, setCurrentSettings] = useState(null);
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await fetchSettingsUtil();
        console.log("settingRes Inside Context",settings)
        setCurrentSettings(settings);
      } catch (error) {
        console.error(error?.message);
      }
    };
    fetchSettings();
  }, []);

  const value = { currentSettings, setCurrentSettings };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
